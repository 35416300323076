<template>
  <div class="columns is-multiline">
    <div class="column is-12">
      <div class="block">
        <p class="title is-6">Clothing Items</p>
        <b-field v-for="clothing in clothingsData" :key="clothing.id">
          <b-checkbox v-model="selectedClothings" :native-value="clothing.name">
            {{ clothing.name }}
          </b-checkbox>
        </b-field>
      </div>
      <div class="buttons is-right">
        <template v-if="action === 'Add'">
          <button class="button btn-120 is-success mt-3" @click="submit()">
            Add
          </button>
        </template>
        <template v-else>
          <button class="button btn-120 is-primary mt-3" @click="submit()">
            Update
          </button>
        </template>
      </div>
    </div>
    <div class="column is-12 pl-0 pt-0">
      <router-link
        class="button is-white"
        :to="`/school/${this.schoolId}/clothings`"
      >
        Add Clothing
      </router-link>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { crudNotification } from '@/assets/js/notification'
import { toCurrency } from '@/assets/js/currency_formatter'

export default {
  data() {
    return {
      clothingsData: [],
      clothingFeeData: [],
      selectedClothings: [],
      selectedClothingIds: [],
      action: 'Add',
    }
  },
  apollo: {
    clothings: {
      query: gql`
        query SchoolFeeDetailAddedServicesQuery($schoolId: ID!) {
          clothings(schoolId: $schoolId) {
            id
            name
            amount
          }
        }
      `,
      variables() {
        return { schoolId: parseInt(this.schoolId) }
      },
    },
  },
  props: {
    schoolId: Number,
    studentId: Number,
    clothingFees: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  watch: {
    clothings(data) {
      this.clothingsData = data.map((item) => {
        return {
          id: item.id,
          name: `${item.name} - ${toCurrency(item.amount, '₦')}`,
        }
      })
    },
    selectedClothings(value) {
      if (!value) return
      if (this.clothingsData.length == 0) return
      this.selectedClothingIds = []
      value.forEach((element) => {
        this.selectedClothingIds.push(
          parseInt(
            this.clothingsData.filter((item) => item.name === element)[0].id
          )
        )
      })
    },
  },
  methods: {
    submit() {
      if (this.action === 'Add') {
        if (this.selectedClothingIds.length === 0) {
          this.$buefy.toast.open({
            duration: 3000,
            message: 'Select a Clothing Item',
            type: 'is-danger',
          })
          return
        } else {
          this.selectedClothingIds.forEach((clothingId) => {
            this.clothingFeeData.push({
              student_id: this.studentId,
              clothing_id: clothingId,
              created_at: new Date().toUTCString(),
              updated_at: new Date().toUTCString(),
            })
          })
        }
        // Add
        this.$apollo
          .mutate({
            mutation: gql`
              mutation CreateClothingFee(
                $studentId: Int!
                $selectedIds: JSON!
              ) {
                createClothingFee(
                  input: { studentId: $studentId, selectedIds: $selectedIds }
                ) {
                  clothingFee {
                    id
                  }
                  errors
                }
              }
            `,
            // Parameters
            variables: {
              studentId: this.studentId,
              selectedIds: this.selectedClothingIds,
            },
          })
          .then((response) => {
            crudNotification(
              response.data.createClothingFee.errors,
              'Successfully created.'
            ).then(() => {
              this.$emit('refetch')
            })
          })
          .catch((error) => {
            console.error(error)
          })
      } else if (this.action === 'Update') {
        // Update
        this.$apollo
          .mutate({
            mutation: gql`
              mutation UpdateClothingFee(
                $studentId: Int!
                $selectedIds: JSON!
              ) {
                updateClothingFee(
                  input: { studentId: $studentId, selectedIds: $selectedIds }
                ) {
                  errors
                }
              }
            `,
            // Parameters
            variables: {
              studentId: this.studentId,
              selectedIds: this.selectedClothingIds,
            },
          })
          .then((response) => {
            crudNotification(
              response.data.updateClothingFee.errors,
              'Successfully updated.'
            ).then(() => {
              this.$emit('refetch')
            })
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },
  },
  mounted() {
    if (this.clothingFees.length > 0) {
      this.action = 'Update'

      this.selectedClothings = this.clothingFees.map(
        (item) =>
          `${item.clothing.name} - ${toCurrency(item.clothing.amount, '₦')}`
      )

      this.selectedClothingIds = this.clothingFees.map((item) =>
        parseInt(item.id)
      )
    }
  },
}
</script>

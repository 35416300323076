<template>
  <div class="columns is-multiline">
    <div class="column is-12">
      <ValidationObserver ref="observer" v-slot="{ passes }">
        <form @submit.prevent="passes(submit)">
          <SdSelect rules="required" label="Book Category" v-model="bookName">
            <option v-for="book in booksData" :key="book.id">
              {{ book.name }}
            </option>
          </SdSelect>
          <div class="buttons is-right">
            <template v-if="action === 'Add'">
              <button type="submit" class="button btn-120 is-success mt-3">
                Add
              </button>
            </template>
            <template v-else>
              <button
                class="button btn-120 is-danger mt-3"
                @click="initializeBookFeeRemove()"
              >
                Remove
              </button>
              <button type="submit" class="button btn-120 is-primary mt-3">
                Update
              </button>
            </template>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <div class="column is-12 pl-0 pt-0">
      <router-link
        class="button is-white"
        :to="`/school/${this.schoolId}/books`"
      >
        Add Books
      </router-link>
    </div>
    <!-- Delete -->
    <app-modal
      id="book-fee"
      context="remove"
      @remove="remove()"
      :show-modal.sync="isModalOpen"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag'
import SdSelect from './SdSelect'
import { ValidationObserver } from 'vee-validate'
import { crudNotification } from '@/assets/js/notification'
import { toCurrency } from '@/assets/js/currency_formatter'

export default {
  data() {
    return {
      booksData: [],
      bookFeeId: null,
      bookId: null,
      bookName: null,
      action: 'Add',
    }
  },
  apollo: {
    books: {
      query: gql`
        query SchoolFeeDetailBooksQuery($schoolId: ID!) {
          books(schoolId: $schoolId) {
            id
            name
            amount
          }
        }
      `,
      variables() {
        return { schoolId: parseInt(this.schoolId) }
      },
    },
  },
  props: {
    schoolId: Number,
    studentId: Number,
    bookFee: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  watch: {
    books(data) {
      this.booksData = data.map((item) => {
        return {
          id: item.id,
          name: `${item.name} - ${toCurrency(item.amount, '₦')}`,
        }
      })
    },
    bookName(value) {
      if (!value) return
      if (!this.books) return
      if (this.books.length == 0) return
      this.bookId = parseInt(
        this.books.filter(
          (item) => `${item.name} - ${toCurrency(item.amount, '₦')}` === value
        )[0].id
      )
    },
  },
  components: {
    SdSelect,
    ValidationObserver,
  },
  methods: {
    initializeBookFeeRemove() {
      this.removeAction = 'Remove Book Fee'
      this.openModal()
    },
    submit() {
      if (this.action === 'Add') {
        // Add
        this.$apollo
          .mutate({
            mutation: gql`
              mutation CreateBookFee($studentId: Int!, $bookId: Int!) {
                createBookFee(
                  input: { studentId: $studentId, bookId: $bookId }
                ) {
                  bookFee {
                    id
                  }
                  errors
                }
              }
            `,
            // Parameters
            variables: {
              studentId: this.studentId,
              bookId: this.bookId,
            },
          })
          .then((response) => {
            crudNotification(
              response.data.createBookFee.errors,
              'Successfully created.'
            ).then(() => {
              this.$emit('refetch')
            })
          })
          .catch((error) => {
            console.error(error)
          })
      } else if (this.action === 'Update') {
        if (this.removeAction !== 'Remove Book Fee') {
          // Update
          this.$apollo
            .mutate({
              mutation: gql`
                mutation UpdateBookFee($id: Int!, $bookId: Int!) {
                  updateBookFee(input: { id: $id, bookId: $bookId }) {
                    bookFee {
                      id
                    }
                    errors
                  }
                }
              `,
              // Parameters
              variables: {
                id: parseInt(this.bookFeeId),
                bookId: this.bookId,
              },
            })
            .then((response) => {
              crudNotification(
                response.data.updateBookFee.errors,
                'Successfully updated.'
              ).then(() => {
                this.$emit('refetch')
              })
            })
            .catch((error) => {
              console.error(error)
            })
        }
      }
    },
    remove() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation DeleteBooktFee($id: Int!, $studentId: Int!) {
              deleteBookFee(input: { id: $id, studentId: $studentId }) {
                bookFee {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: parseInt(this.bookFeeId),
            studentId: this.studentId,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.deleteBookFee.errors,
            'Successfully deleted.'
          ).then(() => {
            this.closeModal()
            this.$emit('refetch')
            this.bookFeeId = null
            this.bookId = null
            this.bookName = null
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
  mounted() {
    if (this.bookFee) {
      this.action = 'Update'

      this.bookFeeId = this.bookFee.id
      this.bookId = this.bookFee.book.id
      this.bookName = `${this.bookFee.book.name} - ${toCurrency(
        this.bookFee.book.amount,
        '₦'
      )}`
    }
  },
}
</script>

<template>
  <div class="columns is-multiline">
    <div class="column is-12">
      <div class="block">
        <p class="title is-6">Added Service Items</p>
        <b-field
          v-for="addedService in addedServicesData"
          :key="addedService.id"
        >
          <b-checkbox
            v-model="selectedAddedServices"
            :native-value="addedService.name"
          >
            {{ addedService.name }}
          </b-checkbox>
        </b-field>
      </div>
      <div class="buttons is-right">
        <template v-if="action === 'Add'">
          <button class="button btn-120 is-success mt-3" @click="submit()">
            Add
          </button>
        </template>
        <template v-else>
          <button class="button btn-120 is-primary mt-3" @click="submit()">
            Update
          </button>
        </template>
      </div>
    </div>
    <div class="column is-12 pl-0 pt-0">
      <router-link
        class="button is-white"
        :to="`/school/${this.schoolId}/added_services`"
      >
        Add Added Service
      </router-link>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { crudNotification } from '@/assets/js/notification'
import { toCurrency } from '@/assets/js/currency_formatter'

export default {
  data() {
    return {
      addedServicesData: [],
      addedServiceFeeData: [],
      selectedAddedServices: [],
      selectedAddedServiceIds: [],
      action: 'Add',
    }
  },
  apollo: {
    addedServices: {
      query: gql`
        query SchoolFeeDetailAddedServicesQuery($schoolId: ID!) {
          addedServices(schoolId: $schoolId) {
            id
            name
            amount
          }
        }
      `,
      variables() {
        return { schoolId: parseInt(this.schoolId) }
      },
    },
  },
  props: {
    schoolId: Number,
    studentId: Number,
    addedServiceFees: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  watch: {
    addedServices(data) {
      this.addedServicesData = data.map((item) => {
        return {
          id: item.id,
          name: `${item.name} - ${toCurrency(item.amount, '₦')}`,
        }
      })
    },
    selectedAddedServices(value) {
      if (!value) return
      if (this.addedServicesData.length == 0) return
      this.selectedAddedServiceIds = []
      value.forEach((element) => {
        this.selectedAddedServiceIds.push(
          parseInt(
            this.addedServicesData.filter((item) => item.name === element)[0].id
          )
        )
      })
    },
  },
  methods: {
    submit() {
      if (this.action === 'Add') {
        if (this.selectedAddedServiceIds.length === 0) {
          this.$buefy.toast.open({
            duration: 3000,
            message: 'Select an Added Service Item',
            type: 'is-danger',
          })
          return
        } else {
          this.selectedAddedServiceIds.forEach((addedServiceId) => {
            this.addedServiceFeeData.push({
              student_id: this.studentId,
              added_service_id: addedServiceId,
              created_at: new Date().toUTCString(),
              updated_at: new Date().toUTCString(),
            })
          })
        }
        // Add
        this.$apollo
          .mutate({
            mutation: gql`
              mutation CreateAddedServiceFee(
                $studentId: Int!
                $selectedIds: JSON!
              ) {
                createAddedServiceFee(
                  input: { studentId: $studentId, selectedIds: $selectedIds }
                ) {
                  addedServiceFee {
                    id
                  }
                  errors
                }
              }
            `,
            // Parameters
            variables: {
              studentId: this.studentId,
              selectedIds: this.selectedAddedServiceIds,
            },
          })
          .then((response) => {
            crudNotification(
              response.data.createAddedServiceFee.errors,
              'Successfully created.'
            ).then(() => {
              this.$emit('refetch')
            })
          })
          .catch((error) => {
            console.error(error)
          })
      } else if (this.action === 'Update') {
        // Update
        this.$apollo
          .mutate({
            mutation: gql`
              mutation UpdateAddedServiceFee(
                $studentId: Int!
                $selectedIds: JSON!
              ) {
                updateAddedServiceFee(
                  input: { studentId: $studentId, selectedIds: $selectedIds }
                ) {
                  errors
                }
              }
            `,
            // Parameters
            variables: {
              studentId: this.studentId,
              selectedIds: this.selectedAddedServiceIds,
            },
          })
          .then((response) => {
            crudNotification(
              response.data.updateAddedServiceFee.errors,
              'Successfully updated.'
            ).then(() => {
              this.$emit('refetch')
            })
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },
  },
  mounted() {
    if (this.addedServiceFees.length > 0) {
      this.action = 'Update'

      this.selectedAddedServices = this.addedServiceFees.map(
        (item) =>
          `${item.addedService.name} - ${toCurrency(
            item.addedService.amount,
            '₦'
          )}`
      )

      this.selectedAddedServiceIds = this.addedServiceFees.map((item) =>
        parseInt(item.id)
      )
    }
  },
}
</script>

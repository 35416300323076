<template>
  <div class="columns is-multiline">
    <div class="column is-12">
      <ValidationObserver ref="observer" v-slot="{ passes }">
        <form @submit.prevent="passes(submit)">
          <SdSelect
            rules="required"
            label="Transport Route"
            v-model="transportRouteName"
          >
            <option
              v-for="transportRoute in transportRoutesData"
              :key="transportRoute.id"
            >
              {{ transportRoute.name }}
            </option>
          </SdSelect>

          <div class="buttons is-right">
            <template v-if="action === 'Add'">
              <button type="submit" class="button btn-120 is-success mt-3">
                Add
              </button>
            </template>
            <template v-else>
              <button
                class="button btn-120 is-danger mt-3"
                @click="initializeTransportFeeRemove()"
              >
                Remove
              </button>
              <button type="submit" class="button btn-120 is-primary mt-3">
                Update
              </button>
            </template>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <div class="column is-12 pl-0 pt-0">
      <a
        class="button is-white"
        :href="`/school/${this.schoolId}/transport_routes`"
      >
        Add Transport Routes
      </a>
    </div>
    <!-- Delete -->
    <app-modal
      id="transport-fee"
      context="remove"
      @remove="remove"
      :show-modal.sync="isModalOpen"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag'
import SdSelect from './SdSelect'
import { ValidationObserver } from 'vee-validate'
import { crudNotification } from '@/assets/js/notification'
import { toCurrency } from '@/assets/js/currency_formatter'

export default {
  data() {
    return {
      transportRoutesData: [],
      transportFeeId: null,
      transportRouteId: null,
      transportRouteName: null,
      action: 'Add',
    }
  },
  apollo: {
    transportRoutes: {
      query: gql`
        query SchoolFeeDetailTransportRoutesQuery($schoolId: ID!) {
          transportRoutes(schoolId: $schoolId) {
            id
            name
            amount
          }
        }
      `,
      variables() {
        return { schoolId: parseInt(this.schoolId) }
      },
    },
  },
  props: {
    schoolId: Number,
    studentId: Number,
    transportFee: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  watch: {
    transportRoutes(data) {
      this.transportRoutesData = data.map((item) => {
        return {
          id: item.id,
          name: `${item.name} - ${toCurrency(item.amount, '₦')}`,
        }
      })
    },
    transportRouteName(value) {
      if (!value) return
      if (!this.transportRoutes) return
      if (this.transportRoutes.length == 0) return
      this.transportRouteId = parseInt(
        this.transportRoutes.filter(
          (item) => `${item.name} - ${toCurrency(item.amount, '₦')}` === value
        )[0].id
      )
    },
  },
  components: {
    SdSelect,
    ValidationObserver,
  },
  methods: {
    initializeTransportFeeRemove() {
      this.removeAction = 'Remove Transport Fee'
      this.openModal()
    },
    submit() {
      if (this.action === 'Add') {
        // Add
        this.$apollo
          .mutate({
            mutation: gql`
              mutation CreateTransportFee(
                $studentId: Int!
                $transportRouteId: Int!
              ) {
                createTransportFee(
                  input: {
                    studentId: $studentId
                    transportRouteId: $transportRouteId
                  }
                ) {
                  transportFee {
                    id
                  }
                  errors
                }
              }
            `,
            // Parameters
            variables: {
              studentId: this.studentId,
              transportRouteId: this.transportRouteId,
            },
          })
          .then((response) => {
            crudNotification(
              response.data.createTransportFee.errors,
              'Successfully created.'
            ).then(() => {
              this.$emit('refetch')
            })
          })
          .catch((error) => {
            console.error(error)
          })
      } else if (this.action === 'Update') {
        if (this.removeAction !== 'Remove Transport Fee') {
          // Update
          this.$apollo
            .mutate({
              mutation: gql`
                mutation UpdateTransportFee(
                  $id: Int!
                  $transportRouteId: Int!
                ) {
                  updateTransportFee(
                    input: { id: $id, transportRouteId: $transportRouteId }
                  ) {
                    transportFee {
                      id
                    }
                    errors
                  }
                }
              `,
              // Parameters
              variables: {
                id: parseInt(this.transportFeeId),
                transportRouteId: this.transportRouteId,
              },
            })
            .then((response) => {
              crudNotification(
                response.data.updateTransportFee.errors,
                'Successfully updated.'
              ).then(() => {
                this.$emit('refetch')
              })
            })
            .catch((error) => {
              console.error(error)
            })
        }
      }
    },
    remove() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation DeleteTransportFee($id: Int!, $studentId: Int!) {
              deleteTransportFee(input: { id: $id, studentId: $studentId }) {
                transportFee {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: parseInt(this.transportFeeId),
            studentId: this.studentId,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.deleteTransportFee.errors,
            'Successfully deleted.'
          ).then(() => {
            this.closeModal()
            this.$emit('refetch')
            this.transportId = null
            this.transportRouteId = null
            this.transportRouteName = ''
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
  mounted() {
    if (this.transportFee) {
      this.action = 'Update'
      this.transportFeeId = this.transportFee.id
      this.transportRouteId = this.transportFee.transportRoute.id
      this.transportRouteName = `${
        this.transportFee.transportRoute.name
      } - ${toCurrency(this.transportFee.transportRoute.amount, '₦')}`
    }
  },
}
</script>

<template>
  <div>
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <div id="student-info">
        <div class="columns">
          <div class="column is-8 is-offset-2">
            <template v-if="student">
              <b-tabs v-model="activeTab">
                <b-tab-item label="Transport Fee">
                  <TransportFee
                    :school-id="schoolId"
                    :student-id="studentId"
                    :transport-fee="transportFee"
                    @refetch="$apollo.queries.student.refetch()"
                  />
                </b-tab-item>
                <b-tab-item label="Book Fee">
                  <BookFee
                    :school-id="schoolId"
                    :student-id="studentId"
                    :book-fee="bookFee"
                    @refetch="$apollo.queries.student.refetch()"
                  />
                </b-tab-item>
                <b-tab-item label="Added Service Fee">
                  <AddedServiceFee
                    :school-id="schoolId"
                    :student-id="studentId"
                    :added-service-fees="addedServiceFees"
                    @refetch="$apollo.queries.student.refetch()"
                  />
                </b-tab-item>
                <b-tab-item label="Clothing Fee">
                  <ClothingFee
                    :school-id="schoolId"
                    :student-id="studentId"
                    :clothing-fees="clothingFees"
                    @refetch="$apollo.queries.student.refetch()"
                  />
                </b-tab-item>
              </b-tabs>
            </template>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import TransportFee from '../../components/TransportFee'
import BookFee from '../../components/BookFee'
import AddedServiceFee from '../../components/AddedServiceFee'
import ClothingFee from '../../components/ClothingFee'

export default {
  data() {
    return {
      schoolId: null,
      studentId: null,
      pageTitle: 'School Fee Detail',
      activeTab: 0,
      student: {
        id: null,
        name: '',
        schoolFee: {},
      },
      transportFee: {
        transportRoute: {
          id: null,
          name: null,
        },
      },
      bookFee: {
        book: {
          id: null,
          name: null,
        },
      },
      addedServiceFees: [],
      clothingFees: [],
    }
  },
  watch: {
    student(data) {
      this.transportFee = data.transportFee
      this.bookFee = data.bookFee
      this.addedServiceFees = data.addedServiceFees
      this.clothingFees = data.clothingFees
      this.$store.commit('setSubMenus', [
        {
          name: 'Student Info',
          route: `/school/${this.$route.params.school_id}/school_class/${data.schoolClassId}/student_info/${data.id}`,
        },
      ])
    },
  },
  components: {
    TransportFee,
    BookFee,
    AddedServiceFee,
    ClothingFee,
  },
  mounted() {
    this.$store.commit('setPageTitle', this.pageTitle)

    this.schoolId = parseInt(this.$route.params.school_id)
    this.studentId = parseInt(this.$route.params.id)

    this.$apollo.addSmartQuery('student', {
      query: gql`
        query student($id: ID!) {
          student(id: $id) {
            id
            name
            schoolClassId
            schoolFee
            transportFee {
              id
              transportRoute {
                id
                name
                amount
              }
            }
            bookFee {
              id
              book {
                id
                name
                amount
              }
            }
            addedServiceFees {
              id
              addedService {
                id
                name
                amount
              }
            }
            clothingFees {
              id
              clothing {
                id
                name
                amount
              }
            }
          }
        }
      `,
      variables() {
        return { id: this.$route.params.id }
      },
      error(error) {
        this.$buefy.notification.open({
          duration: 5000,
          message: error.message,
          position: 'is-top-right',
          type: 'is-danger',
          hasIcon: true,
        })
      },
    })

    this.$apollo.queries.student.refetch()
  },
}
</script>
